import { useAppContexto } from 'contexts/AppContext';
import { Container } from 'reactstrap';

type Props = {
  hasSearch?: boolean;
};

const Navbar = ({ hasSearch }: Props) => {
  const { configuracoes, setConfiguracoes } = useAppContexto();
  if (hasSearch) {
    return (
      <>
        <header id="header" style={{ backgroundColor: configuracoes?.backgroundColorTopo }} className="fixed-top">
          <Container className="d-flex align-items-center justify-content-between">
            <div>
              <a href="/" role="button" style={{ color: configuracoes?.tertiaryColor }}>
                <img style={{ maxHeight: '54px' }} src={`${process.env.NEXT_PUBLIC_URL_LOGO || configuracoes?.urlLogoMarca}?size=small`} />
              </a>
            </div>

            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a style={{ color: configuracoes?.tertiaryColor }} href="/">Inicio</a>
                </li>
                <li>
                  <a
                    href="https://locareweb.com/"
                    role="button"
                    style={{ color: configuracoes?.tertiaryColor }}
                    target="_blank">
                    Login
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </Container>

        </header>
      </>
    );
  }

  return (
    <>
      <header id="header" style={{ backgroundColor: configuracoes?.backgroundColorTopo }} className="fixed-top">
        <Container className="d-flex align-items-center justify-content-between">
          <div>
            <a href="/" role="button" style={{ color: configuracoes?.tertiaryColor }}>
              <img style={{ maxHeight: '54px' }} src={`${process.env.NEXT_PUBLIC_URL_LOGO || configuracoes?.urlLogoMarca}?size=small`} />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a style={{ color: configuracoes?.tertiaryColor }} href="/">Inicio</a>
              </li>
              {/*<li>
                <a className="nav-link scrollto" href="#about">
                  My Broker
                </a>
  </li>*/}
              <li>
                <a className="nav-link scrollto" href="#services" style={{ color: configuracoes?.tertiaryColor }}>
                  Serviços
                </a>
              </li>
              <li>
                <a href="/busca-rapida" role="button" style={{ color: configuracoes?.tertiaryColor }}>
                  Pesquisar
                </a>
              </li>
              {/*  <li>
                <a className="nav-link scrollto" href="#personal">
                  Trabalhe Conosco
                </a>
  </li>*/}
              <li>
                <a className="nav-link scrollto" href="#contact" style={{ color: configuracoes?.tertiaryColor }}>
                  Contato
                </a>
              </li>
              <li>
                <a href="https://locareweb.com/" role="button" target="_blank" style={{ color: configuracoes?.tertiaryColor }}>
                  Login
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </Container>
      </header>
    </>
  );
};

export default Navbar;
