import { Form, Input, Tooltip } from 'antd';
import { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import Notify from '@components/Notify';
import { useAppContexto } from 'contexts/AppContext';

const { Search } = Input;

const NewsLetter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { configuracoes, setConfiguracoes } = useAppContexto();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      Notify('warning', '', 'Não foi possível enviar, tente mais tarde');
    }, 1200);
  };

  return (
    <>
      <div className="newsletter">
        <div className="d-flex flex-column h-100 justify-content-center py-4">
          <Container>
            <Row className="justify-content-center no-gutters">
              <Col xs="10" md="4">
                <div className="py-3">
                  <a href="/" role="button">
                    <img className='footer-logo' src={`${process.env.NEXT_PUBLIC_URL_LOGO || configuracoes?.urlLogoMarca}?size=small`} />
                  </a>
                </div>
              </Col>
              {/* <Col xs="10" md="4">
                <div>
                  <h2>NewsLetter</h2>
                  <p>Receba nossa NewsLetter</p>

                  <Form form={form} scrollToFirstError>
                    <Tooltip title="Funcionalidade em construção...">
                      <Row className="mt-2 justify-content-md-center no-gutters">
                        <Form.Item className="my-2" name="email">
                          <Search
                            placeholder=" Email"
                            allowClear
                            enterButton="Enviar"
                            loading={isLoading}
                            onSearch={() => { }}
                          />
                        </Form.Item>
                      </Row>
                    </Tooltip>
                  </Form>
                </div>
              </Col> */}
              {!!configuracoes && <Col xs="10" md="4">
                <div className="newsletter-social">
                  {configuracoes?.urlFacebook && (<div className="newsletter-social-items">
                    <a
                      href={`https://www.facebook.com/${configuracoes?.urlFacebook}`}
                      target="_blank">
                      <img
                        src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/logo-face.png?size=small"
                        height={35}
                        width={35}
                      />
                    </a>
                  </div>)}
                  {configuracoes?.urlYoutube && (<div className="newsletter-social-items">
                    <a
                      href={`https://www.youtube.com/${configuracoes?.urlYoutube}`}
                      target="_blank">
                      <img
                        src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/logo-yt.png?size=small"
                        height={35}
                        width={35}
                      />
                    </a>
                  </div>)}
                  {/* <div className="newsletter-social-items">
                    <a href="#" target="_blank">
                      <img src="/img/logo-twitter.png" height={55} width={55} />
                    </a>
                  </div> */}
                  {configuracoes?.urlInstagram && (<div className="newsletter-social-items">
                    <a
                      href={`https://www.instagram.com/${configuracoes?.urlInstagram}/`}
                      target="_blank">
                      <img
                        src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/logo-instagram.png?size=small"
                        height={35}
                        width={35}
                      />
                    </a>
                  </div>)}
                  {configuracoes?.urlX && (<div className="newsletter-social-items">
                    <a
                      href={`https://www.x.com/${configuracoes?.urlX}/`}
                      target="_blank">
                      <img
                        src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/logo-x.png?size=small"
                        height={35}
                        width={35}
                      />
                    </a>
                  </div>)}
                </div>
              </Col>}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
